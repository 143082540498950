import { fetchConfig } from '@/lib/fetchConfig';
import { useQuery } from '@tanstack/react-query';

export const queryContact = `query($locale: String!) {
  # add your query
  categoryPageCollection(
    where: { internalName: "contact" }
    limit: 1
    locale: $locale
  ) {
    items {
      ... on CategoryPage {
        pageName
        slug
        __typename
        titleDescription {
          json
        }
        titleLinksCollection {
          __typename
          items {
            ... on NavigationElement {
              __typename
              title
              urlPath
            }
          }
        }
        contentCollection {
          __typename
          items {
            ... on ArticleSection {
              sectionContentCollection(limit: 10) {
                items {
                  title
                  featuredTitle
                  featuredDescription{
                    json
                  }
                  slug
                  category
                  featuredImage {
                    width
                    height
                    url
                    title
                  }
                }
              }
              __typename
              title
              layout
              numberOfLoadedContent
              sys {
                id
              }
            }
            ... on CardSection {
              __typename
              internalTitle
              sectionPadding
              title
              layout
              sectionContentCollection(limit: 10) {
                items {
                  ... on Card {
                    __typename
                    shortDescription {
                      json
                    }
                    onlyImagevideo
                    videoUrl
                    linkUrl
                    title
                    internalTitle
                    linkIconTitle,
                    cardTemplate
                    linkMailto
                      cardImage{
                      width
                      height
                      url
                      title
                    }
                    icon {
                      url
                      title
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        containerForCustomButton {
          ... on NavigationButton {
            __typename
            internalTitle
            buttonText
            buttonUrl
            document {
              url
            }
          }
        }
        seo {
          title
          description
          canonicalUrl
          nextPage
          noindex
          nofollow
          image {
            url
            width
            height
            title
            description
          }
          ldType
          ldAuthor
          ldAuthorUrl
          ldPublished
          ldUpdated
        }
      }
    }
  }
}
`;

const fetchContactPageData = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryContact, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useContactData = (locale: any) => {
  return useQuery({
    queryKey: [`contact_${locale}`],
    refetchOnWindowFocus: false,
    queryFn: () => fetchContactPageData(locale)
  });
};

export { useContactData, fetchContactPageData };
