import { useQuery } from '@tanstack/react-query';
import { fetchConfig } from '@/lib/fetchConfig';

export const queryIndustries = `query($locale: String!) {
  # add your query
  categoryPageCollection( 
    limit: 1
    where: { internalName: "industries" }
    locale: $locale
  ) {
    items {
      ... on CategoryPage {
        pageName
        slug
        __typename
        titleDescription {
          json
        }
        titleLinksCollection {
          __typename
          items {
            ... on NavigationElement {
              __typename
              title
              urlPath
            }
          }
        }
        contentCollection {
          __typename
          items {
            ... on CardSection {
              __typename
              internalTitle
              sectionPadding
              layout
              sectionContentCollection(limit: 20) {
                items {
                  ... on Card {
                    __typename
                    shortDescription {
                      json
                    }
                    cardTemplate
                    cardImage{
                      width
                      height
                      url
                      title
                    }
                    onlyImagevideo
                    videoUrl
                    linkUrl
                    title
                    internalTitle
                    linkIconTitle,
                    linkMailto
                      cardImage{
                      width
                      height
                      url
                      title
                    }
                    icon {
                      url
                      title
                      width
                      height
                    }
                  }
                }
              }
            }
            ... on ArticleSection {
              __typename
              sectionPadding
              sys {
                id
              }
              title
              layout
              numberOfLoadedContent
              sectionContentCollection(limit: 10) {
                items {
                  ... on Article {
                        __typename
                    featuredTitle
                    featuredDescription{
                      json
                    }
                    slug
                    category
                    featuredImage {
                      width
                      height
                      url
                      title
                    }
                  }
                }
              }
            }
            ...on PersonBanner{
              __typename
              sys{
                id
              }
              internalTitle
              quote
              personName
              picturePosition
              pictureOfPerson{
                title
                description
                width
                height
                url
              }
              linkedIn{
                urlPath
                title
                document{
                title
                description
                url
                width
                height
                }
              }
              personName
              personPosition
              personMail
            }
          }
        }
        containerForCustomButton {
          ... on NavigationButton {
            __typename
            internalTitle
            buttonText
            buttonUrl
            document {
              url
            }
          }
        }
        seo {
          title
          description
          canonicalUrl
          nextPage
          noindex
          nofollow
          image {
            url
            width
            height
            title
            description
          }
          ldType
          ldAuthor
          ldAuthorUrl
          ldPublished
          ldUpdated
        }
      }
    }
  }
}
`;

const fetchIndustriesPageData = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryIndustries, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useIndustriesData = (locale: any) => {
  return useQuery({
    queryKey: ['industries', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchIndustriesPageData(locale)
  });
};

export { useIndustriesData, fetchIndustriesPageData };
