import { fetchConfig } from '@/lib/fetchConfig';
import { useQuery } from '@tanstack/react-query';

export const queryExpertiseArticles = `query($category: String! ) {
  articleCollection(limit: 100, where: {category: $category}){
    items{
      ...on Article{
        slug
        subCategory
        seo {
          title
          description
          canonicalUrl
          nextPage
        }
      }
    }
  }
}
`;

export const queryExpertiseArticle = `query($locale: String!, $category: String!, $slug: String!, $subCategory: String!) {
  articleCollection(limit: 1, locale: $locale, where: { category: $category, slug: $slug, subCategory: $subCategory }) {
    items {
      ... on Article {
        slug
        category
        subCategory
        featuredTitle
        featuredDescription {
          json
        }
        published
        writtenBy
        content {
          json
          links {
            assets {
              hyperlink {
                url
              }
              block {
                sys {
                  id
                }
                url
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on PicturesInRow {
                  title
                  imagesCollection {
                    items {
                      url
                    }
                  }
                }
                ... on YouTubeVideo {
                  videoUrl
                }
                ... on PictureAndTextInRow {
                  titleRow
                  description {
                    json
                  }
                  picturePosition
                  picture {
                    title
                    url
                    width
                    height
                  }
                }
                ... on CardSection {
                  __typename
                  internalTitle
                  title
                  layout
                  sectionContentCollection(limit: 10) {
                    items {
                      ... on Card {
                        __typename
                        onlyImagevideo
                        cardTemplate
                        linkUrl
                        linkIconTitle
                        title
                        videoUrl
                        shortDescription {
                          json
                        }
                        cardImage{
                            width
                            height
                            url
                            title
                          }
                        }
                      ... on Accordion {
                        __typename
                        title
                        description {
                          json
                        }
                      }
                    }
                  }
                }
                ... on Quote {
                  quoteText
                }
                ... on Accordion {
                  __typename
                  title
                  description {
                    json
                  }
                }
                  ... on NavigationButton {
                    __typename
                    showTitle
                    internalTitle
                    buttonText
                    buttonUrl
                    document {
                      url
                    }
                  }  
              }
            }
          }
        }
      }
      virtualShowRoom {
        ... on Card {
          __typename
          cardTemplate
          cardImage {
            title
            width
            height
            url
          }
          shortDescription {
            json
          }
          linkUrl
          title
          internalTitle
          linkIconTitle
          linkMailto
          cardImage {
            width
            height
            url
            title
          }
          icon {
            url
            title
            width
            height
          }
        }
      }
      featuredProjectsCollection {
        items {
          __typename
          ... on ArticleSection {
            layout
            title
            sectionContentCollection {
              items {
                ... on Article {
                  slug
                  subCategory
                  category
                  featuredTitle
                  featuredDescription {
                    json
                  }
                  featuredImage {
                    title
                    url
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
      nextPageNavigation {
        internalTitle
        buttonText
        buttonUrl
      }
      sidebarNextPageNavigationCollection {
        items {
          internalTitle
          buttonText
          buttonUrl
        }
      }
      personBanner {
        ... on PersonBanner {
          internalTitle
          title
          quote
          personName
          personPosition
          picturePosition
          personMail
          pictureOfPerson {
            title
            description
            width
            height
            url
          }
          linkedIn {
            title
            urlPath
            document {
              title
              description
              url
              width
              height
            }
          }
        }
      }
      seo {
        title
        description
        canonicalUrl
        nextPage
        noindex
        nofollow
        image {
          url
          width
          height
          title
          description
        }
        ldType
        ldAuthor
        ldAuthorUrl
        ldPublished
        ldUpdated
      }
    }
  }
}
`;

const fetchExpertiseArticle = async (
  locale: string,
  category: string,
  subCategory: string,
  slug: string
) => {
  const variables = { locale, category, subCategory, slug };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryExpertiseArticle, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useExpertiseArticle = (
  locale: any,
  category: string,
  subCategory: any,
  slug: any
) => {
  return useQuery({
    queryKey: [slug, locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchExpertiseArticle(locale, category, subCategory, slug)
  });
};

export { useExpertiseArticle, fetchExpertiseArticle };
