import { fetchConfig } from '@/lib/fetchConfig';
import { fetchJobConfig } from '@/lib/fetchJobConfig';
import { useQuery } from '@tanstack/react-query';

export const queryCareers = `query($locale: String!) {
  # add your query
  categoryPageCollection(
    where: { internalName: "Careers Page" }
    limit: 1
    locale: $locale
  ) {
    items {
      ... on CategoryPage {
        pageName
        slug
        __typename
        titleDescription {
          json
        }
        titleLinksCollection {
          __typename
          items {
            ... on NavigationElement {
              __typename
              title
              urlPath
            }
          }
        }
        contentCollection {
          __typename
          items {
            ... on ArticleSection {
              sectionContentCollection(limit: 10) {
                items {
                  title
                  featuredTitle
                  featuredDescription{
                    json
                  }
                  slug
                  category
                  featuredImage {
                    width
                    height
                    url
                    title
                  }
                }
              }
              __typename
              title
              layout
              numberOfLoadedContent
              sys {
                id
              }
            }
            ...on Quote {
              quoteText
            }
            ...on AwardsAndCertificates {
                    __typename
                    title
                    imagesCollection {
                      items {
                        title
                        description
                        url
                        size
                      }
                    }
                  }
            ... on CardSection {
              __typename
              internalTitle
              title
              sectionPadding
              layout
              sectionContentCollection(limit: 20) {
                items {
                  ... on Card {
                    __typename
                    shortDescription {
                      json
                    }
                    cardTemplate
                    cardImage{
                      width
                      height
                      url
                      title
                    }
                    onlyImagevideo
                    videoUrl
                    linkUrl
                    title
                    internalTitle
                    linkIconTitle,
                    linkMailto
                      cardImage{
                      width
                      height
                      url
                      title
                    }
                    icon {
                      url
                      title
                      width
                      height
                    }
                  }
                }
              }
            }
            ... on SliderSection {
              __typename
              title
          	  contentCollection(limit: 10) {
                items {
                  title
                  secondaryTitle
                  description
                  slideImage {
                    url
                    width
                    height
                  }
                }
              }
            }
            ...on Quote {
              __typename
              quoteText
              author
              authorTitle
            }
            ... on ContentFlag {
              __typename
              internalTitle
            }
            ...on PersonBanner {
              __typename
              internalTitle
              locations
              personName
              personPosition
              personMail
              quote
              linkedIn{
                title
                urlPath
                document{
                  title
                  url
                  description
                  width
                  height
                }
              }
              pictureOfPerson {
                title
                description
                url
                width
                height
              }
            }
            ... on PersonBannerSection{
            	__typename
              title
              internalTitle
              layout
              numberOfLoadedContent
              sectionContentCollection(limit: 10) {
                items{
                  ...on PersonBanner{
                    __typename
                    internalTitle
                    locations
                    quote
                    picturePosition
                    pictureOfPerson{
                      title
                      description
                      url
                      width
                      height
                    }
                    linkedIn{
                      title
                      urlPath
                      document{
                        title
                        url
                        description
                        width
                        height
                      }
                    }
                    personName
                    personPosition
                    personMail
                  }
                }
              }
          	}
          }
        }
        containerForCustomButton {
          ... on NavigationButton {
            __typename
            internalTitle
            buttonText
            buttonUrl
            document{
              url
            }
          }
        }
        seo {
          title
          description
          canonicalUrl
          nextPage
          noindex
          nofollow
          image {
            url
            width
            height
            title
            description
          }
          ldType
          ldAuthor
          ldAuthorUrl
          ldPublished
          ldUpdated
        }
      }
    }
  }
}
  `;

export const queryPersonBanner = `query($locale: String!) {
 personBannerCollection(
    where: { locations_exists: true, quote_exists: true }
    limit: 10
    locale: $locale
  ) {
    items {
      ...on PersonBanner {
        __typename
        internalTitle
        locations
        quote
        picturePosition
        pictureOfPerson {
          title
          description
          url
          width
          height
        }
        linkedIn {
          title
          urlPath
          document {
            title
            url
            description
            width
            height
          }
        }
        personName
        personPosition
        personMail
      }
    }
  }
}`;

const fetchCareersPageData = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryCareers, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const useCareersData = (locale: any) => {
  return useQuery({
    queryKey: ['careers', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchCareersPageData(locale)
  });
};

const getJobsCount = async () => {
  const url = `${fetchJobConfig.endpoint}/offers/`;
  const response = await fetch(url as string, {
    method: 'GET',
    ...fetchJobConfig.params
  });
  const json = await response.json();
  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }
  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }
  return json.offers.length;
};

const fetchJobsData = async () => {
  const apiUrl = `${fetchConfig.jobsEndpoint}/offers`;
  const res = await fetch(apiUrl as string, {
    method: 'GET',
    ...fetchConfig.jobParams
  });
  const json = await res.json();
  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.offers;
};

const useJobsData = (locale: any) => {
  return useQuery({
    queryKey: ['jobs', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchCareersPageData()
  });
};

const useCountData = (locale: any) => {
  return useQuery({
    queryKey: ['jobscount', locale],
    refetchOnWindowFocus: false,
    queryFn: () => getJobsCount()
  });
};

const fetchPersonBannerData = async (locale = 'de') => {
  const variables = { locale };
  const res = await fetch(fetchConfig.endpoint as string, {
    method: 'POST',
    ...fetchConfig.params,
    body: JSON.stringify({ query: queryPersonBanner, variables })
  });
  const json = await res.json();

  if (json.errors) {
    const { message } = json.errors[0];

    throw new Error(message);
  }

  return json.data;
};

const usePersonBannerData = (locale: any) => {
  return useQuery({
    queryKey: ['personBannerData', locale],
    refetchOnWindowFocus: false,
    queryFn: () => fetchPersonBannerData()
  });
};

export {
  useCareersData,
  fetchCareersPageData,
  fetchJobsData,
  fetchPersonBannerData,
  getJobsCount,
  useCountData,
  useJobsData,
  usePersonBannerData
};
